<template>
  <div>
    <ConsentNotice v-if="requireConsentFile" />
    <ValidationObserver
      v-else
      ref="form"
      tag="form"
      class="user-form"
      @submit.prevent="handleSubmit">
      <div v-if="$store.state.user.consentCheckSuccess">
        Загруженный файл согласия прошел проверку.
      </div>

      <slot name="top"></slot>

      <div
        v-if="educationPending"
        class="edu-placeholder">
        <BaseSpinner brand />
      </div>
      <div v-else>
        <div class="row">
          <div
            v-if="requireEducationRegion"
            class="col-md-8 col-xs-12 mb-l">
            В&nbsp;этом году финалы НТО Junior пройдут на&nbsp;региональных
            площадках. Распределение по&nbsp;этим площадкам происходит
            в&nbsp;соответствии с&nbsp;регионом школы участника. Пожалуйста,
            убедитесь, что регион вашего образовательного учреждения указан
            верно. В&nbsp;случае ошибки мы&nbsp;не&nbsp;сможем допустить вас
            к&nbsp;финалу.
          </div>
          <div
            v-if="!isSchoolInvite"
            class="col-md-6 col-xs-12">
            <div class="form-row">
              <label class="form-label"
                >{{ isStudent ? "Место учебы" : "Школа" }} *</label
              >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <BaseSelect
                  v-model="currentEducation"
                  placeholder="Школа"
                  :options="educationOptions"
                  theme="reg"
                  :allow-empty="false"
                  track-by="id"
                  label="title"
                  :searchable="false" />
                <div
                  v-if="errors"
                  class="form-error">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div
            v-if="!is7GradeUpperRegistration"
            class="col-md-6 col-xs-12">
            <label class="form-label"
              >{{ isStudent ? "Курс/Класс" : "Класс" }} *</label
            >
            <div class="row">
              <div class="col-md-4 col-sm-6 col-xs-12">
                <ValidationProvider
                  v-slot="{ errors }"
                  mode="aggressive"
                  rules="required">
                  <BaseSelect
                    v-model="grade"
                    theme="reg"
                    placeholder=""
                    :allow-empty="false"
                    :searchable="false"
                    track-by="value"
                    label="title"
                    :options="gradeOptions" />
                  <div
                    v-if="errors"
                    class="form-error">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div
                v-if="isStudent"
                class="col-sm-6 col-xs-12">
                <div class="form-row">
                  На&nbsp;каком курсе вы&nbsp;учитесь в&nbsp;{{
                    seasonRange || "текущем"
                  }}
                  учебном году?
                </div>
              </div>
              <div
                v-else
                class="col-sm-6 col-xs-12">
                <div class="form-row">
                  В&nbsp;каком классе вы&nbsp;учитесь в&nbsp;{{
                    seasonRange || "текущем"
                  }}
                  учебном году?
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Education Create Form -->
        <div v-if="isNewEducation && !isSchoolInvite">
          <div
            v-if="isStudent"
            class="row">
            <div class="col-md-4 col-sm-6 col-xs-12 form-row">
              <label class="form-label">Тип образования *</label>
              <BaseSelect
                v-model="currentCategory"
                placeholder="Тип образования"
                :options="educationCategoryOptions"
                :allow-empty="false"
                :preselect-first="true"
                theme="reg"
                :max-height="250"
                label="name"
                :searchable="false" />
            </div>
            <div
              v-if="isHighEducation"
              class="col-md-4 col-sm-6 col-xs-12 form-row">
              <label class="form-label">Факультет *</label>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <BaseInput
                  v-model="newEducation.faculty"
                  :errors="errors"
                  theme="reg" />
              </ValidationProvider>
            </div>
            <div class="col-md-4 col-sm-6 col-xs-12 form-row">
              <label class="form-label">Специальность *</label>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <BaseInput
                  v-model="newEducation.speciality"
                  :errors="errors"
                  theme="reg" />
              </ValidationProvider>
            </div>
          </div>
          <label class="form-label"
            >{{
              isStudent
                ? "Название образовательного учреждения"
                : "Название школы"
            }}
            *</label
          >
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12 mb-xs">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: isNewEducation }">
                <EducationAutocomplete
                  v-model="newEducationData"
                  :errors="errors"
                  theme="reg"></EducationAutocomplete>
              </ValidationProvider>
            </div>
            <div class="col-md-7 col-sm-12 col-xs-12">
              <div
                v-if="isStudent"
                class="form-row">
                Начните вводить название своего образовательного учреждения,
                например: &laquo;МГУ&raquo;. Если среди предложенных нет нужного
                варианта, укажите название в&nbsp;свободной форме
              </div>
              <div
                v-else
                class="form-row">
                Начните вводить название школы, например &laquo;Школа №20&nbsp;,
                Тобольск&raquo; немного подождите и&nbsp;выберите вашу школу
                в&nbsp;выпадающем списке. Если нужной школы там нет, укажите
                название в&nbsp;свободной форме.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            v-if="requireEducationAddress"
            class="col-md-5 col-sm-12 col-xs-12">
            <div class="form-row">
              <label class="form-label"
                >Город образовательного учреждения *</label
              >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required">
                <AddressAutocomplete
                  v-model="newEducationAddress"
                  :errors="errors"
                  search-endpoint="/self/suggestions/address/"
                  :list-mapper="
                    (item) => {
                      return {
                        label: item.value,
                        value: item,
                      };
                    }
                  "
                  :search-options="geoSearchOptions"
                  theme="reg" />
              </ValidationProvider>
            </div>
          </div>
          <div
            v-if="allowMerchant"
            class="col-md-7 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xs-12">
                <label class="form-label">Размер футболки</label>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-6 mb-xs">
                <BaseSelect
                  v-model="merchSize"
                  theme="reg"
                  placeholder="Класс"
                  :allow-empty="false"
                  preselect-first
                  :searchable="false"
                  :options="sizesOptions" />
              </div>
              <div class="col-md-7 col-sm-6 col-xs-12">
                <div class="form-row">
                  Эта информация нужна для изготовления брендированного мерча
                  НТО.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="educationRegion && requireEducationRegion">
        <div class="hr"></div>
        <div>
          <div class="confirm-row">
            Регион школы: <span class="region">{{ educationRegion }}</span>
            <BaseButton
              type="button"
              @click.prevent="handleConfirmRegion"
              >Да</BaseButton
            >
            <BaseButton
              type="button"
              theme="reg"
              @click.prevent="handleRejectRegion"
              >Нет</BaseButton
            >
          </div>
        </div>
      </template>

      <div class="hr"></div>

      <div
        v-if="error"
        class="form-error mb-m">
        {{ error }}
      </div>
      <!-- /Education Create Form -->
      <div
        v-if="isRegistrationClosed"
        class="form-error mb-l text-size-m">
        Регистрация на этот трек закрыта.
      </div>
      <div v-if="is4GradeJunior">
        <div class="row mt-m">
          <div class="col-md-8 col-xs-12">
            <p class="mb-s">
              <strong class="text-bold">Обратите внимание!</strong>
            </p>
            <p class="mb-s">
              Четвероклассники могут принять участие только вне конкурса
              и&nbsp;только в&nbsp;отборочном этапе НТО Junior. Они могут
              познакомиться с&nbsp;олимпиадой, ее&nbsp;особенностями
              и&nbsp;задачами, но&nbsp;не&nbsp;будут приглашены в&nbsp;финал.
            </p>
            <p>
              Мы&nbsp;рады, что вы&nbsp;решились попробовать свои силы,
              и&nbsp;будем ждать вас на&nbsp;НТО Junior в&nbsp;следующем году!
            </p>
          </div>
        </div>
        <hr class="hr" />
      </div>

      <div class="btn-row btn-row--reverse">
        <BaseButton
          v-if="!isFinalist"
          id="reg-btn-track-school"
          theme="reg"
          tag="router-link"
          class="mr-s mb-s"
          title="Вернуться к выбору роли"
          :to="{ name: 'registration', query: $route.query }"
          >Назад</BaseButton
        >
        <BaseButton
          :disabled="disabledRegBtn"
          :loading="pending"
          type="submit"
          class="mr-s mb-s"
          >Продолжить</BaseButton
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  PARTICIPANT_ROLE,
  HIGH_EDUCATION_ALIAS,
  SECONDARY_EDUCATION_ALIAS,
  LOW_SCHOOL_ALIAS,
  MERCH_SIZES,
  GRADE_OPTIONS,
  STUDENT_ALIAS,
  JUNIOR_ALIAS,
  SCHOOL_ALIAS,
  SECONDARY_PROF_EDUCATION_ALIAS,
} from "@/constants";
import { talentRequest, request } from "@/services/api";
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete";
import EducationAutocomplete from "@/components/autocomplete/EducationAutocomplete";
import { mapGetters } from "vuex";
import ConsentNotice from "./ConsentNotice";
import dayjs from "@/plugins/dayjs";
const newEducationOption = (isStudent) => {
  return {
    id: "new",
    title: isStudent ? "+ Добавить новое" : "+ Добавить школу",
  };
};

const initialEducationFormState = (title = "") => {
  return {
    title: title,
    dadata_hid: "",
    // Только если нет dadata_hid
    address: "",
    // только для студентов
    faculty: "",
    speciality: "",

    // Это мы не отправляем на бек - но учитываем
    // для валидации региона финала для джунов

    region_with_type: "",
  };
};
export default {
  name: "RegistrationUserForm",
  components: {
    AddressAutocomplete,
    EducationAutocomplete,
    ConsentNotice,
  },
  props: {
    talentUser: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
      validator(value) {
        return [STUDENT_ALIAS, SCHOOL_ALIAS].includes(value);
      },
    },
    schedule: {
      type: Array,
    },
    /**
     * Регистрация финалиста прошлого сезона
     */
    isFinalist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentEducation: newEducationOption(this.isStudent),
      educations: [],
      grade: "",
      merchSize: MERCH_SIZES[3], // M
      newEducation: initialEducationFormState(),
      error: "",
      pending: false,
      userCreated: false,
      educationCreated: false,
      educationPending: true,
      isUserOrgMember: false,
      currentCategory: "",
      isRegionConfirmed: false,
      rootEventRequestId: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      seasonRange: "seasonRange",
      rootEvent: "rootEvent",
    }),
    isNewEducation() {
      return this.currentEducation?.id === "new";
    },
    isStudent() {
      return this.role === STUDENT_ALIAS;
    },
    /**
     * Обязательный адрес для обр. учреждения
     * если оно взято не из справочника
     */
    requireEducationAddress() {
      const { isNewEducation, newEducation } = this;
      if (this.isSchoolInvite) return false;
      return (
        (isNewEducation && newEducation.title && !newEducation.dadata_hid) ||
        (!isNewEducation && !this.selectedUserEducation?.region_with_type)
      );
    },
    educationOptions() {
      const { isStudent } = this;
      const studentTypes = [
        SECONDARY_PROF_EDUCATION_ALIAS,
        HIGH_EDUCATION_ALIAS,
      ];
      const list = this.educations.filter((n) => {
        return isStudent
          ? studentTypes.includes(n.category)
          : n.category === SECONDARY_EDUCATION_ALIAS;
      });

      return [...list, newEducationOption(this.isStudent)];
    },
    newEducationData: {
      get() {
        return this.newEducation.title;
      },
      set(value = {}) {
        const hasRegionInfo = !!value?.region_with_type;
        let result = {
          title: value.title,
          dadata_hid: value.dadata_hid,
          region_with_type: value.region_with_type,
        };

        if (this.isJuniorRegistration) {
          // не выставляем хид если нет данных о регионе
          result = {
            ...result,
            dadata_hid: hasRegionInfo ? value.dadata_hid : "",
          };
        }
        this.newEducation = Object.assign(this.newEducation, result);
      },
    },

    newEducationAddress: {
      get() {
        return this.newEducation.address;
      },
      set(val) {
        if (!val) {
          this.newEducation.address = "";
          this.newEducation.region_with_type = "";
        }
        this.newEducation.address = val?.unrestricted_value || "";
        this.newEducation.region_with_type = val?.data?.region_with_type || "";
      },
    },
    sizesOptions() {
      return MERCH_SIZES;
    },
    gradeOptions() {
      const { isStudent, is7GradeUpperRegistration } = this;
      if (is7GradeUpperRegistration) {
        return [
          {
            title: "7 класс",
            value: 7,
          },
        ];
      }
      if (isStudent) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((n) => {
          return {
            value: n,
            title: n,
          };
        });
      }
      return GRADE_OPTIONS.map((n) => ({
        title: n,
        value: n,
      }));
    },
    isHighEducation() {
      return (
        this.isStudent && this.currentCategory?.value === HIGH_EDUCATION_ALIAS
      );
    },
    requireConsentFile() {
      return this.$store.getters["user/requireConsentFile"];
    },
    tracks() {
      return Object.values(this.$store.state.tracks);
    },
    isRegistrationClosed() {
      const { schedule, isStudent, grade } = this;
      if (!schedule?.length) return false;
      let alias;
      if (isStudent) {
        alias = STUDENT_ALIAS;
      } else {
        // Для школьников, закрытость расписания будет зависеть от класса
        if (!grade) return false;
        if ([5, 6, 7].includes(grade.value)) {
          alias = JUNIOR_ALIAS;
        } else if ([8, 9].includes(grade.value)) {
          alias = LOW_SCHOOL_ALIAS;
        } else {
          alias = SCHOOL_ALIAS;
        }
      }
      const currentStage = schedule.find((n) => n.track?.alias === alias);
      if (!currentStage || !currentStage.end_at) return false;
      return dayjs().isAfter(currentStage.end_at);
    },
    disabledRegBtn() {
      let disabled = !!this.requireConsentFile || !!this.isRegistrationClosed;

      // if (this.requireEducationRegion) {
      //   return disabled || !this.isRegionConfirmed;
      // }

      return disabled;
    },
    is7GradeUpperRegistration() {
      return this.$route.query.upper_track;
    },
    // Регистрация на джуниор трек
    // требует указания городов финала,
    // 4-клашкам - не нужно
    isJuniorRegistration() {
      return (
        this.grade?.value <= 7 &&
        !this.isStudent &&
        !this.is7GradeUpperRegistration
      );
    },
    /**
     * Является ли регистрация приглашением от школы.
     */
    isSchoolInvite() {
      return (
        !this.isUserOrgMember &&
        this.$route.query.org_invite &&
        this.$route.query.org
      );
    },
    invitedEducationId() {
      const { educations, isSchoolInvite } = this;
      if (!isSchoolInvite) return false;
      const education = educations.find(
        (edu) => edu.organization === +this.$route.query.org
      );
      return education?.id;
    },
    is4GradeJunior() {
      return !this.isStudent && this.grade && this.grade.value === 4;
    },
    educationCategoryOptions() {
      return [
        {
          name: "Высшее",
          value: HIGH_EDUCATION_ALIAS,
        },
        {
          name: "Среднее-специальное",
          value: SECONDARY_PROF_EDUCATION_ALIAS,
        },
      ];
    },

    educationRegion() {
      if (this.isNewEducation) {
        return this.newEducation?.region_with_type;
      }
      return this.selectedUserEducation?.region_with_type;
    },

    allowMerchant() {
      return this.isStudent || (this.grade && !this.isJuniorRegistration);
    },

    requireEducationRegion() {
      return (
        this.isJuniorRegistration &&
        !this.isSchoolInvite &&
        !this.is4GradeJunior
      );
    },
    geoSearchOptions() {
      const result = {
        from_bound: { value: "city" },
        to_bound: { value: "settlement" },
        locations: [
          {
            country: "*",
          },
        ],
      };
      return result;
    },
    /**
     * Существующее образование юзера выбранное в форме
     */
    selectedUserEducation() {
      if (this.isNewEducation) return undefined;
      if (this.currentEducation?.id) {
        return this.educations.find((n) => n.id === this.currentEducation.id);
      }
      return undefined;
    },
  },
  watch: {
    role() {
      const edu = this.educationOptions[0];
      this.currentEducation = edu;
      this.grade = "";
    },
  },
  async created() {
    this.getUserEducations();
    const { isStudent } = this;
    window.dataLayer?.push({
      event: "ntoRegformEventRole",
      eventCategory: "nto_regform",
      eventAction: "choose_role",
      userRole: isStudent ? "student" : "pupil",
      userChoice: "selected_role",
    });
    try {
      await this.$store.dispatch("getTracks");
    } catch (error) {
      console.log("error", error);
    }
    await this.getRootEventRequest();
  },

  methods: {
    async getRootEventRequest() {
      if (!this.rootEvent) return;
      try {
        const { data } = await talentRequest({
          url: `/api/users/${this.talentUser?.id}/requests/`,
          params: {
            event: this.rootEvent,
            limit: 1,
            offset: 0,
          },
        });
        if (data?.results?.[0]) {
          this.rootEventRequestId = data.results[0].id;
        }
      } catch (error) {
        // ничего не делаем
        this.rootEventRequestId = null;
      }
    },
    async createEventRequest() {
      const { data } = await talentRequest({
        url: `/api/events/${this.rootEvent}/requests/`,
        method: "POST",
      });
      this.rootEventRequestId = data.id;
      return data?.id;
    },
    async createEducation() {
      const { isStudent, newEducation, grade, currentCategory } = this;
      const payload = {
        category: isStudent
          ? currentCategory?.value
          : SECONDARY_EDUCATION_ALIAS,
        start: new Date().getFullYear() - (grade.value - 1),
        title: newEducation.title,
        ...(newEducation.dadata_hid && { dadata_hid: newEducation.dadata_hid }),
        ...(newEducation.address && { address: newEducation.address }),
        extra: {
          ...(newEducation.faculty && { faculty: newEducation.faculty }),
          ...(newEducation.speciality && {
            speciality: newEducation.speciality,
          }),
        },
      };
      const { data } = await talentRequest({
        method: "POST",
        url: `/api/users/${this.talentUser.id}/educations/`,
        data: payload,
      });
      return data.id;
    },
    async getUserEducations() {
      this.educationPending = true;
      const { talentUser, is7GradeUpperRegistration } = this;
      try {
        const { data } = await talentRequest({
          url: `/api/users/${talentUser.id}/educations/`,
          params: {
            limit: 100,
            offset: 0,
          },
        });
        if (data?.results?.length > 0) {
          this.educations = data.results;
          const current =
            this.educationOptions.find((n) => n.current) ||
            this.educationOptions[0];

          if (current?.grade && !is7GradeUpperRegistration) {
            this.grade = this.gradeOptions.find(
              (n) => n.value === current.grade
            );
          }
          this.currentEducation = current;
        } else {
          this.currentEducation = newEducationOption(this.isStudent);
        }
      } catch (error) {
        this.currentEducation = newEducationOption(this.isStudent);
      }

      if (is7GradeUpperRegistration) {
        this.grade = this.gradeOptions[0];
      }
      this.educationPending = false;
    },
    async patchUserEducation(id, data) {
      try {
        await talentRequest({
          method: "PATCH",
          url: `/api/users/${this.talentUser.id}/educations/${id}/`,
          data: data,
        });
      } catch (error) {
        // ничего не делаем
      }
    },
    async patchTalentUser(payload) {
      try {
        await this.$store.dispatch("user/updateUserInfo", payload);
      } catch (error) {
        this.$notify({
          title: "Ошибка",
          text: `Не удалось обновить данные учетной записи талант. ${error.message}`,
          group: "base",
          duration: 10000,
        });
      }
    },
    async handleSubmit() {
      if (this.pending) return;
      if (!this.rootEvent) {
        this.error =
          "Не удалось получить данные о мероприятии сезона. Пожалуйста, сообщите нам об этой ошибке";
        return;
      }
      const { isStudent } = this;
      const isValid = await this.$refs.form.validate();
      this.error = "";
      if (!isValid) return;

      if (this.requireEducationRegion && !this.isRegionConfirmed) {
        this.error =
          "Необходимо подтвердить регион образовательного учреждения";
        return;
      } else if (this.requireEducationRegion && !this.educationRegion) {
        this.error = "У выбранного образовательного учреждения не указан адрес";
        return;
      }

      this.pending = true;

      let education_id = this.currentEducation.id;
      // 1. Создаем пользователя в НТО, если его еще нет
      if (!this.user?.role && !this.userCreated) {
        try {
          await request({
            method: "POST",
            url: "/user/register",
            data: {
              role: PARTICIPANT_ROLE,
            },
          });
          this.userCreated = true;
        } catch (error) {
          this.error = error.message;
          this.pending = false;
          return;
        }
      }
      // 2. Разбираемся с образованием
      if (this.isSchoolInvite) {
        // Если это инвайт в школу, то необходимо записать пользователя в члены
        // этой организации, и подтянуть список его образований
        if (this.invitedEducationId) {
          // Сначала проверив, что у него еще нет такой связи
          // если такое образование уже есть, то не будем инватиться
          education_id = this.invitedEducationId;
        } else {
          try {
            const grade = this.grade?.value || 1;
            await talentRequest({
              method: "POST",
              url: `/api/users/${this.user.talent_id}/organizations/${this.$route.query.org}/member/invite/`,
              data: {
                code: this.$route.query.org_invite,
                user: this.talentUser.id,
                event: +this.$route.query.event,
                education: {
                  extra: {},
                  start: new Date().getFullYear() - grade,
                },
              },
            });
            // Повторно запросим образования юзера, и проверим что там создалось нужное
            await this.getUserEducations();

            if (!this.invitedEducationId) {
              // Если по каким то причинам, у юзера новое образование не создалось,
              // то попросим юзера ввести данные об образовании
              this.pending = false;
              this.error = "Укажите данные об образовательном учреждении";
              return;
            } else {
              // Если все ок, то запишим id этого образования
              education_id = this.invitedEducationId;
            }
          } catch (error) {
            this.pending = false;
            if (
              error.message ===
              "Пользователь уже является участником организации"
            ) {
              /**
               * Может быть кейс, когда юзер уже является участником ораганизации,
               * а это значит, что образование не будет создано. По этому, просим пользователя
               * указать данные об образовании
               */
              this.pending = false;
              this.error = "Укажите данные об образовательном учреждении";
              this.isUserOrgMember = true;
              return;
            }
            this.error = error.message;
          }
        }
      } else if (this.isNewEducation && !this.educationCreated) {
        // 2. Если выбрано новое образовательное учреждение
        // и мы его еще не создавали
        try {
          this.educationCreated = education_id = await this.createEducation();
        } catch (error) {
          this.error = error.message;
          this.pending = false;
          return;
        }
      } else if (!this.isNewEducation && this.requireEducationAddress) {
        // Обновим данные об образовании в таланте
        await this.patchUserEducation(education_id, {
          address: this.newEducation.address,
        });
      }

      // обновим данные о юзере в таланте
      this.patchTalentUser({
        grade: this.grade.value,
        current_education: education_id,
      });

      if (!this.rootEventRequestId) {
        try {
          await this.createEventRequest();
        } catch (error) {
          this.error = error.message;
          this.pending = false;
          return;
        }
      }

      // Регистрируем участие юзера в новом сезоне
      const regData = {
        grade: this.grade.value,
        talent_education_id: education_id,
        event_request_id: this.rootEventRequestId,
        ...(this.allowMerchant && { merch_size: this.merchSize }),
      };

      const gtmPayload = {
        event: "ntoRegformEventForm",
        eventCategory: "nto_regform",
      };

      if (isStudent) {
        regData.grade = null;
        gtmPayload.userNtovariation = "student_nto";
        gtmPayload.eventAction = "form_filled_student";
      } else {
        // Если это регистрация 7-классника на старший трек
        if (this.is7GradeUpperRegistration) {
          const schoolTrack = this.tracks.find(
            (n) => n.alias === LOW_SCHOOL_ALIAS
          );
          if (schoolTrack) {
            regData.track_id = schoolTrack.id;
          }
        }
        gtmPayload.eventAction = "form_filled_pupil";
      }

      const { code, invited_by } = this.$route.query;

      if (code && invited_by) {
        regData.code = code;
        regData.invited_by = invited_by;
      }
      /**
       * Если это регистрация финалиста, то
       * эмитим событие с регистрационными данными и выходим из функции
       */
      if (this.isFinalist) {
        this.$emit("onSubmitFinalist", regData);
        this.pending = false;
        return;
      }

      try {
        await request({
          method: "POST",
          url: "/participant",
          data: regData,
        });
        if (this.educationCreated) {
          gtmPayload.userStudy = this.newEducation.title;
        } else {
          gtmPayload.userStudy = this.currentEducation?.title;
        }

        if (!isStudent) {
          gtmPayload.userCity = this.talentUser?.city;
          gtmPayload.userClass = regData.grade;
          if (regData.grade <= 7 && !regData.track_id) {
            gtmPayload.userNtovariation = "junior_nto";
          } else {
            gtmPayload.userNtovariation = "main_nto";
          }
        }
        window.dataLayer?.push(gtmPayload);
      } catch (error) {
        this.error = error.message;
        this.pending = false;
        return;
      }
      // Получаем данные о пользователе
      try {
        await this.$store.dispatch("user/getMe", true);
        await this.$store.dispatch("participant/getInfo");
      } catch (error) {
        console.log("error", error);
      }

      this.$router.push({
        name: "registration-profile",
        query: this.$route.query,
      });
    },
    handleConfirmRegion() {
      this.isRegionConfirmed = true;
      if (this.isRegistrationClosed) return;
      this.handleSubmit();
    },
    handleRejectRegion() {
      const title = this.isNewEducation
        ? this.newEducation.title
        : this.currentEducation.title;
      this.currentEducation = newEducationOption(this.isStudent);
      this.newEducationData = initialEducationFormState(title);
      this.isRegionConfirmed = false;
    },
  },
};
</script>

<style lang="less" scoped>
.reg-warning {
  margin-bottom: 20px !important;
}
.edu-placeholder {
  min-height: 200px;
}

.level-box {
  margin-top: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &__check {
    display: inline-block;
    border: 2px solid @lp-outline-color;
    width: 1.2em;
    height: 1.2em;
    border-radius: 3px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.5em;
    position: relative;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 0.6em;
      height: 0.8em;
      border: 0.2em solid lighten(@lp-outline-color, 20%);
      transform: translate(-50%, -100%) rotateZ(-45deg);
      border-left: 0;
      border-top: 0;
      left: 50%;
      top: 50%;
      margin-top: -0.2em;
      margin-left: 0.1em;
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
      pointer-events: none;
    }
  }

  &__check.is-checked::before {
    opacity: 1;
    transform: translate(-50%, -50%) rotateZ(45deg);
  }
}

.region {
  font-size: 1.4em;
  font-weight: bold;
  color: #fff;
}

.confirm-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}
</style>
